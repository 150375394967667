import { callApiService } from '@/_services'
const publicKey = '376317e03aa6b6ccab3d'
const apiUrl = 'https://api.uploadcare.com'

const API_URL = process.env.VUE_APP_URL

export const uploadcareService = {
  generateHash,
  deleteImage,
  setQrCode
}

async function generateHash () {
  return callApiService.callApi(`${API_URL}uploadcareSignature`, 'get')
}

async function setQrCode (file) {
  const formdata = new FormData()
  formdata.append('blob', file, 'qrcode')

  return callApi(`${apiUrl}/files/`, 'post', formdata)
}

async function deleteImage (uuid) {
  const url = `${apiUrl}/files/${uuid}/storage/`
  return callApi(url, 'delete', '')
}

/**
 * Call UploadCare project
 *
 * @param {String} url contain the url of requisition
 * @param {String} method contain the method of requisition
 * @param {Object | Null} body contain the body of requisition
 * @param {Object} additionalHeaders optional headers
 * @returns {Object}
 */
async function callApi (url, method, body, additionalHeaders = {}) {
  const params = await generateHash()
  const headers = {
    Authorization: `Uploadcare ${publicKey}:${params.signature}`,
    Accept: 'application/vnd.uploadcare-v0.7+json',
    ...additionalHeaders
  }

  const request = { method, headers }

  if (method !== 'get' && body) {
    request.body = body instanceof FormData ? body : JSON.stringify(body)
  }

  try {
    const result = await fetch(url, request).then(r => r.json())
    return result
  } catch (error) {
    console.error('Error in uploadcare service:', error)
    return null
  }
}
