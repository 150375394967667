<template>
  <div v-on:keyup.esc="fullscreen = false">
    <Menu v-show="!fullscreen"></Menu>
    <div style="padding-top: 3rem;" v-if="surveys.length === 0 && !loading">
      <div class="row">
        <app-heading level="h3" class="col-lg-12 col-md-12 col-sm-12" style="text-align: center; margin-top: 30px;">{{ $t('surveyList.no_surveys_msg') }}</app-heading>
        <app-heading level="h4" class="col-lg-12 col-md-12 col-sm-12" style="text-align: center; margin-top: 20px;">{{ $t('surveyList.create_first_survey') }}</app-heading>
      </div>
      <div class="row center-xxs center-xs center-sm center-md center-lg" style="padding-top: 3rem;">
        <div class="btn">
          <app-button class="btn-light-accent" v-on:click="openModal">{{ $t("start.from_stratch") }}</app-button>
          <app-button class="btn-outline-secondary" v-on:click="goToTemplates" >{{ $t("start.from_template") }}</app-button>
        </div>
        <app-modal
          v-model="isModalOpen"
          :title="$t('start.modal_survey_title')"
          lightbox
          close
          class="modal-default-primary"
          :primary-button="$t('start.create')"
          @primaryButtonClick="setSurvey"
          v-on:keyup.esc="closeModal"
          :class="{ 'disabled-btn': loadingModal }"
          >
          <app-form-input
            v-model="surveyTitle"
            :placeholder="$t('start.survey_title_placeholder')"
            secondary
            autofocus
            v-on:keyup.enter="setSurvey"
            v-on:keyup.esc="closeModal"
          >
          </app-form-input>
          <div class="data-loading-realtime" v-if="loadingModal">
            <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
          </div>
        </app-modal>
      </div>
    </div>
    <div class="row mt15" v-if="selectedSurvey._id && surveys.length > 0">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 survey-selector">
        <v-select
          class="realTimeVselect change-select"
          label="title"
          :clearable="false"
          v-title="$t('questionsNav.survey')"
          :options="surveys"
          v-model="selectedSurvey"
          @input="getDataAndUpdateCurrentSurvey(true)"
        >
          <template #option="{ title, isExpired }">
            <p :class="{'option-expired': isExpired}">
              {{ title }}
              <i v-if="isExpired" class="material-icons expired-icon-select" v-title="$t('surveyList.survey_expired')">timer_off</i>
            </p>
          </template>
        </v-select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" v-title="$t('sendConfig.period')">
        <div style="margin-top: -30px;">
          <div>
            <app-form-input
              secondary
              @click="isActive = !isActive"
              v-model="labelDate"
              class="period-filter col-xs-12 col-sm-12 col-md-12 col-lg-12"
            >
            </app-form-input>
            <svg
              class="arrowForSelectDate"
              :class="{ 'open-flag': isActive }"
              style="margin-top: -30px; position: absolute"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="10"
            >
              <path :d="svgPath"/>
            </svg>
          </div>
        </div>
        <transition v-if="isActive" name="dropdown">
          <div @click="isActive = !isActive" v-on:mouseleave="isActive = false" class="row dropdown__menu drop-style">
            <div
              v-for="mockDate in rangeOfDates"
              tabindex="0"
              @focusout="isActive = false"
              @click="currentDate  = mockDate;labelDate = $t(`date_filter.periods.${mockDate}`); getDataAndUpdateCurrentSurvey()"
              :key="mockDate"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 filterDateButton"
            >
              {{$t(`date_filter.periods.${mockDate}`)}}
            </div>
          </div>
        </transition>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3" v-if="socketResponses.length > 0 && selectedSurvey.questions">
        <div class="views-card row center-lg center-md center-sm center-xs">
          <div class="views-btn col-lg-3 col-md-3 col-sm-3 col-xs-3" style="padding-top: 3px !important" v-for="(button, index) in buttons" :key="index">
            <app-button
              v-title="button.label"
              inline
              :icon="button.icon"
              icon-color="gray"
              icon-size="sm"
              class="px-0 my-0 mr-1 colored"
              :class="{ 'isActive': view === button.view }"
              v-on:click="selectView(button.view)"
            ></app-button>
          </div>
          <div class="fullscreen-btn col-lg-3 col-md-3 col-sm-3 col-xs-3" style="padding-top: 3px !important">
            <app-button
              v-title="$t('real_time.fullscreen')"
              inline
              icon="fullscreen"
              icon-color="gray"
              icon-size="sm"
              class="px-0 my-0 mr-1 colored"
              :class="{ 'isActive': fullscreen }"
              v-on:click="tvDashPage()"
            ></app-button>
          </div>
        </div>
      </div>
    <!-- toggle scroll will go here -->
    <div class="scroll-page-toggle col-lg-2 col-md-2 col-sm-2" v-if="fullscreen">
      <div class="center-lg center-md center-sm center-xs">
        <app-toggle-input v-model="isScrollAllowed" class="scroll-toggle"></app-toggle-input>
        <app-heading level="h6">{{ $t('real_time.auto_scroll') }}</app-heading>
      </div>
    </div>
    </div>
    <div class="data-loading-fixed" v-if="loading || !canShowThePage">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <RealTimeGrouper
      v-if="showGraphicsOrNotFound"
      :survey="selectedSurvey"
      :responses="socketResponses"
      :realTimeQuestions="realTimeQuestions"
      :realTimeTexts="realTimeTexts"
      :matrixData="matrixData"
      :controlAllComponents="key"
      :fullscreen="fullscreen"
      :isScrollAllowed="isScrollAllowed"
    ></RealTimeGrouper>
      <NoDataToShow v-if="socketResponses.length === 0 && selectedSurvey.questions && !loading && !silentLoading && surveys.length > 0 && canShowThePage" title="dashboard.no_data_to_show.title" subtitle="dashboard.no_data_to_show.subtitle"></NoDataToShow>
    <theme-style>
      .isActive > div > i,
      .isActive > div > span {
        color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
      }
      .arrowForSelectDate {
        fill:  {{user.account.theme.colors.accent || '#ff607d'}};
      }
    </theme-style>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Menu from '../components/Menu.vue'
import { gridMaker, formatters, responseOptions, iconFormatter } from '@/_helpers'
import { surveyService, dashboardsService } from '@/_services'
import NoDataToShow from '../components/alerts/NoDataToShow.vue'
import RealTimeGrouper from '../components/dashboard/realTime/Grouper.vue'
const io = require('socket.io-client')
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})
export default {
  name: 'RealTime',
  components: {
    Menu,
    NoDataToShow,
    RealTimeGrouper,
    vSelect: () => import('vue-select'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput')
  },
  data () {
    return {
      isModalOpen: false,
      loadingModal: false,
      surveyTitle: '',
      canShowThePage: false,
      buttons: [
        { view: 'average', icon: 'dashboard', label: this.$t('dashboard.views.average'), isActive: true },
        { view: 'overtime', icon: 'chart-line', label: this.$t('dashboard.views.overtime'), isActive: false },
        { view: 'overall', icon: 'answers', label: this.$t('dashboard.views.overall'), isActive: false }
      ],
      rangeOfDates: ['day', 'week', 'month', 'semester', 'year'],
      realTimeQuestions: [],
      silentLoading: false,
      socketResponses: [],
      currentDate: '',
      entity: 'real-time',
      selectedSurvey: {},
      realTimeTexts: [],
      matrixData: [],
      auxQuestionsArray: [],
      fullscreen: false,
      isScrollAllowed: false,
      isActive: false,
      canShow: false,
      loading: true,
      labelDate: '',
      surveys: [],
      survey: {},
      socket: {},
      key: 0,
      svgPath: 'M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z'
    }
  },
  destroyed () {
    document.removeEventListener('fullscreenchange', this.closeDash)
    document.removeEventListener('webkitfullscreenchange', this.closeDash)
    document.removeEventListener('mozfullscreenchange', this.closeDash)
    document.removeEventListener('MSFullscreenChange', this.closeDash)
    try {
      clearInterval(this.interval)
    } catch (error) {
      console.log('error to destroy', error.message)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    }),
    view () {
      return this.$store.getters['dashboard/getRealTimeView']
    },
    showGraphicsOrNotFound () {
      let canShow = this.socketResponses.length
      this.realTimeQuestions.forEach(data => {
        if (data && data.questions) {
          data.questions.forEach(question => {
            if (question.total) {
              canShow = true
            }
          })
        }
      })
      return canShow
    },
    interval () { return this.$store.getters['dashboard/getRealTimeInterval'] },
    currentSurvey () { return this.$store.getters['dashboard/getCurrentSurvey'] }
  },
  async mounted () {
    this.loading = true
    const defaultLabel = this.user.account.dashboardDefaultPeriod || 'year'
    this.currentDate = defaultLabel
    this.labelDate = this.$t(`date_filter.periods.${defaultLabel}`)
    this.$nextTick(async () => {
      document.addEventListener('fullscreenchange', this.closeDash)
      document.addEventListener('webkitfullscreenchange', this.closeDash)
      document.addEventListener('mozfullscreenchange', this.closeDash)
      document.addEventListener('MSFullscreenChange', this.closeDash)
    })

    this.$root.$on('realtime-redirect-to-responses', (obj) => {
      const query = this.getCurrentQuery()
      const queryByOptions = responseOptions.mountQueryToRedirect(obj.question, obj.clickedOption, query)
      this.$router.push({ name: 'Individual', query: { q: queryByOptions } })
    })

    const token = localStorage.getItem('bindsUser')
      ? JSON.parse(localStorage.getItem('bindsUser'))
      : ''

    this.socket = io.connect(process.env.VUE_APP_BASE_URL)

    this.socket.on('connect', () => {
      this.socket.emit('authenticate', { token })
        .on('authenticated', () => {
          console.log('Connected dashboard socket')
          this.initializeSocketListeners()
        })
        .on('unauthorized', msg => {
          throw new Error(msg.data.type)
        })
    })

    this.initializeSocketListeners = () => {
      this.socket.on('responses', responses => {
        this.processResponses(responses)
      })

      this.socket.on('survey', survey => {
        this.processSelectSurvey(survey)
      })

      this.socket.on('surveyResponse', response => {
        this.processSurveyResponse(response)
      })

      this.canShowThePage = true
    }

    this.processResponses = responses => {
      this.socketResponses = responses
    }

    this.processSelectSurvey = survey => {
      this.selectedSurvey = survey
    }

    this.processSurveyResponse = response => {
      if (!response) return

      response.referralEntriesQty = response.referralEntries ? response.referralEntries.length : 0

      response.contact = response.seedData ? response.seedData.from : {}

      response.added = true

      this.socketResponses.forEach(answer => {
        answer.added = false
      })

      this.socketResponses.unshift(response)
    }

    this.surveys = await surveyService.getAllSurveys()
    // TODO: talvez eu mude isso aqui depois
    if (this.surveys.length === 0) {
      return this.$router.push('Start')
    }
    this.selectedSurvey = this.currentSurvey ? this.currentSurvey : this.surveys[0]

    let query = typeof this.$route.query.q === 'string' ? JSON.parse(this.$route.query.q) : this.$route.query.q
    if (!query) {
      query = { $and: [] }
      this.$router.push({ path: '/realtime', query: { q: JSON.stringify(query) } })
    }

    const hasSurveyOnQuery = await window._.find(query.$and, 'survey')
    if (hasSurveyOnQuery) {
      const survey = window._.find(this.surveys, { _id: hasSurveyOnQuery.survey.$eq })
      if (survey) {
        this.selectedSurvey = survey
      }
    }

    const hasDateOnQuery = await window._.find(query.$and, 'realTimeDate')
    if (hasDateOnQuery) {
      const date = hasDateOnQuery.realTimeDate
      if (date) {
        this.currentDate = date
      }
    }

    this.getDataAndUpdateCurrentSurvey(true)
    this.refreshPageBySeconds()
  },
  methods: {
    openModal () {
      this.isModalOpen = true
    },
    closeModal () {
      this.isModalOpen = false
    },
    async setSurvey () {
      this.loadingModal = true
      const titleSurvey = this.surveyTitle.trim()
      if (!titleSurvey && titleSurvey.length === 0) {
        this.loadingModal = false
        return this.commitError(this.$i18n.t('start.template_title_error'), 'bottomRight', false)
      }
      await this.$store.commit('survey/startEmptySurvey', this.$store.state)
      const survey = await surveyService.createSurvey({ title: titleSurvey, lang: this.$i18n.vm.fallbackLocale.toLowerCase() })
      if (!survey._id) {
        this.loadingModal = false
        return this.commitError(this.$i18n.t('start.error_creating'), 'bottomRight', false)
      } else {
        // set color theme on survey
        const getTheme = survey.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
        survey.colors = getTheme
        // set icons on navigation menu
        const shared = iconFormatter.getSharedIcons()
        survey.questions.forEach(element => {
          const questionModel = shared[window._.findIndex(shared, { type: element.type })]
          element.icon = questionModel.icon
          // Precisamos fazer isso para pesquisas antigas que não têm a propriedade de estrelas nas questions
          if (!element.stars) {
            element.stars = { active: false, numbers: false }
          }
        })
        // FIRSt QUESTION SELECTED value Default
        survey.questionSelected = 0
        survey.selectedEndMessageIndex = undefined
        survey.answeredMessageDetails = {}

        this.$store.commit('survey/survey', survey)
        this.loadingModal = false
        this.$router.push({ name: 'Surveys', params: { id: survey._id } })
      }
    },
    goToTemplates () {
      this.$router.push({ name: 'Templates' })
    },
    closeDash () {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        this.fullscreen = false
        return false
      }
      this.fullscreen = true
    },
    tvDashPage () {
      if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        }
        return false
      }

      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    },
    selectView (view) {
      this.loading = true
      this.$store.commit('dashboard/updateRealTimeView', view)
      this.getRealTime()
    },
    refreshPageBySeconds () {
      const newInterval = setInterval(async (self) => {
        // se não tiver fazendo outra request de load, então faz uma nova
        if (!self.loading && !self.silentLoading) {
          self.silentLoading = true
          await self.getRealTime()
        }
      }, 25000, this)
      this.$store.commit('dashboard/updateRealTimeInterval', newInterval)
    },
    getCurrentQuery () {
      let date = new Date()
      switch (this.currentDate) {
        case 'day': date = this.$moment().subtract(1, 'days').format('DD/MM/YYYY')
          break
        case 'week': date = this.$moment().subtract(1, 'weeks').format('DD/MM/YYYY')
          break
        case 'month': date = this.$moment().subtract(1, 'months').format('DD/MM/YYYY')
          break
        case 'semester': date = this.$moment().subtract(6, 'months').format('DD/MM/YYYY')
          break
        case 'year': date = this.$moment().subtract(1, 'years').format('DD/MM/YYYY')
          break
        default: date = this.$moment().subtract(1, 'days').format('DD/MM/YYYY')
          break
      }
      date = this.$moment(date, 'DD/MM/YYYY').utc().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      let dateTo = this.$moment(new Date(), 'DD/MM/YYYY').utc().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      if (this.currentDate === 'day') {
        dateTo = this.$moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        date = this.$moment(dateTo).subtract(21, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      }

      const query = {
        $and: [
          {
            survey: { $eq: this.selectedSurvey._id }
          },
          {
            respondedAt: { $gte: date, $lte: dateTo }
          }
        ]
      }

      const objectToLoad = {
        $and: [
          {
            survey: { $eq: this.selectedSurvey._id }
          },
          {
            respondedAt: { $gte: date, $lte: dateTo }
          },
          {
            realTimeDate: this.currentDate
          }
        ]
      }
      this.labelDate = this.$t(`date_filter.periods.${this.currentDate}`)
      this.$router.push({ path: this.$route.path, query: { q: JSON.stringify(objectToLoad) } }).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
          // But print any other errors to the console
          console.log(err)
        }
      })
      return JSON.stringify(query)
    },
    async getDataAndUpdateCurrentSurvey (surveyChanged) {
      this.loading = true
      this.auxQuestionsArray = []
      if (surveyChanged) {
        this.selectedSurvey = await surveyService.getSurvey(this.selectedSurvey._id)
        this.$store.commit('dashboard/updateCurrentSurvey', this.selectedSurvey)
      }
      this.getRealTime()
      this.emitFilterChange()
    },
    emitFilterChange () {
      const filterObject = {
        tzOffset: new Date().getTimezoneOffset(),
        period: this.currentDate, // day|week|month|year
        grid: gridMaker.getDashboardGrid(this.currentDate),
        survey: this.selectedSurvey._id
      }
      this.socket.emit('statsFilter', filterObject)
    },
    async getRealTime () {
      if (this.view === 'average' || this.view === 'overall') {
        const query = formatters.formatDateByAddTimezone(this.getCurrentQuery(), 3, 'hours')
        this.realTimeQuestions = await dashboardsService.getSyntheticData(query)
        // Fill aux array to deal with texts questions laters
        if (this.realTimeQuestions.length > 0) {
          this.realTimeQuestions[0].questions.forEach(i => {
            const index = this.auxQuestionsArray.findIndex(question => question._id === i.question._id)
            if (index < 0) {
              this.auxQuestionsArray.push(i.question)
            }
          })
        }
      }

      if (this.view === 'overtime') {
        const gridRange = gridMaker.getGrid(gridMaker.getDataFromQuery(this.getCurrentQuery()).$gte, gridMaker.getDataFromQuery(this.getCurrentQuery()).$lte)
        const overtime = await dashboardsService.getOvertimeData(formatters.formatDateByAddTimezone(this.getCurrentQuery(), 3, 'hours'), gridRange)
        this.realTimeQuestions = overtime
        /*
          When it's "overtime" view, the request returns us a different result, that can't be used to build
          the text questions' request. If we reload the page with "overtime" view selected, it won't bring
          text question results again, so we'll request it again, as "average" would do.
        */
        if (this.auxQuestionsArray.length >= 0) {
          this.matrixData = await dashboardsService.getSyntheticData(formatters.formatDateByAddTimezone(this.getCurrentQuery(), 3, 'hours'))
          if (this.matrixData.length > 0) {
            this.matrixData[0].questions.forEach(i => {
              const index = this.auxQuestionsArray.findIndex(question => question._id === i.question._id)
              if (index < 0) {
                this.auxQuestionsArray.push(i.question)
              }
            })
          }
        }
      }

      // Dealing with text questions
      const fetchAll = []
      if (this.auxQuestionsArray.length > 0) {
        this.auxQuestionsArray.forEach(i => {
          if (i.type === 'text') {
            const query = formatters.formatDateByAddTimezone(this.getCurrentQuery(), 3, 'hours')
            const defaultRequestOfTypeText = dashboardsService.getTextDash({ q: query, cleanEmptyQuestions: false }, i._id)
            fetchAll.push(defaultRequestOfTypeText)
          }
        })
      }

      const resultTexts = await Promise.all(fetchAll)

      this.realTimeTexts = resultTexts.map(item => item.data)

      this.$emit('update')
      this.silentLoading = false
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
.data-loading-fixed {
  z-index: 50;
  background: #ffffff80;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option-expired {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 3px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #efefef;
}

.option-expired:hover {
  background: #5897fb;
  color: #ffffff;
}

.scroll-page-toggle {
  margin-left: 25px;

  @media screen and (max-height: 800px) {
    margin-left: 15px;

    &.scroll-page-toggle > div {
      width: 150%;
    }
  }

  @media screen and (min-height: 1320px) {
    margin-left: 45px;

    &.scroll-page-toggle > div {
      width: 142%;
    }
  }

  > div {
      margin-top: -14px !important;
      padding: 14px;
      display: flex;
      justify-content: center;
      background-color: #fff;
      height: 49px;
      width: 145%;
      border-radius: 5px;
      box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03), 0 5px 4px 0 rgba(0, 0, 0, 0.01) !important;
      -webkit-box-sizing: border-box;
      -webkit-box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03), 0 5px 4px 0 rgba(0, 0, 0, 0.01) !important;
  }

  .scroll-toggle {
    cursor: pointer;
    margin-right: 50px;
  }
}

.views-card {
  margin-top: -14px !important;
  cursor: pointer;
  width: 100%;
  height: 49px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03), 0 5px 4px 0 rgba(0, 0, 0, 0.01) !important;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03), 0 5px 4px 0 rgba(0, 0, 0, 0.01) !important;
}
.views-btn {
  text-align: center;
  display: inline-block !important;
  & .b-icon {
    font-size: 20px !important;
  }
}
.fullscreen-btn {
  text-align: center;
  display: inline-block !important;
  & .b-icon {
    font-size: 18px !important;
  }
}
.drop-style {
  height: 370px !important;
  z-index: 999999 !important;
  left: 33.6vw !important;
  width: 16vw !important;
  margin-top: -16px !important;
  position: absolute !important;
}
.arrowForSelectDate {
  fill: '#00000000';
  transform: scale(1);
  transition: transform 150ms cubic-bezier(1.000, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1.000, -0.115, 0.975, 0.855);
}
.open-flag {
  transform: rotate(180deg) scale($open-indicator-size);
  transition: rotate 1.5s scale 1.5s;
}
.change-select .vs__selected {
  color: #080808 !important;
}
.period-filter {
  & .text-field__input {
    box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03), 0 5px 4px 0 rgba(0, 0, 0, 0.01) !important;
    background-color: #fcfcfc !important;
    & input {
      color: #080808 !important;
      width: 92% !important;
      text-align: center;
    }
  }
}
@media (max-width: 646px) {
  .realTimeVselect > .vs__dropdown-toggle > .vs__selected-options > .vs__selected {
    line-height: 0.9 !important;
    font-size: 0.9em !important;
  }
}
.data-loading-realtime {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  .data-loading-spinner {
    margin-top: 102px !important;
    margin-left: 45% !important;
    & .spinner-inner {
      background-color: #fff !important;
    }
  }
}
</style>
