<template>
  <div class="container-config-wpp" v-if="myTemplate">
    <div class="section-config-wpp">
      <div class="header-config-wpp">
        <app-heading level="h5" class="title-config-wpp">{{ $t('whatsapp.config_template') }}</app-heading>
      </div>
      <div class="content-config-wpp">
        <div class="name-config-wpp">
          <app-heading level="h6" class="title-name-config-wpp">{{ $t('whatsapp.config_name') }}</app-heading>
          <div class="select-name-config-wpp">
            <div class="input-edit-title">
              <div class="div-auto-resize" v-click-outside="closeOptionsDisplay">
                <edit-title
                  @updateNameTitle="updateNameTitle"
                  @openOptions="optionsDisplayTitle = true"
                  :editStyle="true"
                  :optionsDisplay="optionsDisplayTitle"
                  :identify="`whatsapp-name`"
                  :maxlength='charactersLimit'
                  :minHeight="80"
                  :maxHeight="80"
                  :title="newText"
                  :color="user.account.theme.colors.secondary"
                  :metadata.sync="metadataGroup"
                  type="textarea"
                />
              </div>
            </div>
            <div class="count-text-wpp">
              <app-heading level="h7">{{ $t('sendhub.smsHub.charactersRemaining') }} : {{ charactersLeft.remaining }}</app-heading>
              <app-heading level="h7">{{ $t('sendhub.smsHub.charactersLimit') }} : {{ charactersLeft.limit }}</app-heading>
            </div>
            <i class="material-icons icon-help-name-wpp" v-title="$t('whatsapp.tooltip_help_name')" title-placement="left">help</i>
          </div>
        </div>
        <div class="select-image-config-wpp" v-if="myTemplate?.type === 'image'">
          <i class="material-icons icon-help-img-wpp" v-title="$t('whatsapp.tooltip_help_image')" title-placement="left">help</i>
          <app-heading level="h6" class="title-img-config-wpp">{{ $t('whatsapp.config_img') }}</app-heading>
          <div class="content-image-config-wpp">
            <uploadcare
              class="content-upload-img-wpp"
              v-if="wppImage"
              :context="'whatsapp'"
              :crop="wppImage.crop"
              :imagesOnly="wppImage.imagesOnly"
              :imageShrink="wppImage.imageShrink"
              :cdnBase="'https://img.binds.co'"
              :UPLOADCARE_LOCALE="'pt'"
              @success="onSuccess"
              @error="onError"
            >
              <div class="set-image">
                <div v-if="!wppImage.success">
                  <i class="material-icons" style="color: #cacaca">image</i>
                  <button class="btn-change-img-wpp">
                    <i class="material-icons add-image-wpp">add</i>
                  </button>
                </div>
                <div v-else>
                  <img class="img-container" :src="returnUrl()"/>
                  <button class="btn-change-img-wpp">
                    <i class="material-icons add-image-wpp">border_color</i>
                  </button>
                </div>
              </div>
            </uploadcare>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditTitle from '../EditTitle.vue'

export default {
  name: 'WhatsappConfig',
  props: [],
  components: {
    uploadcare: () => import('../../components/image/UploadCare.vue'),
    EditTitle,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      newText: '',
      lengthMessage: 0,
      metadataGroup: [
        {
          key: '_name',
          label: 'Nome',
          groupable: false,
          type: 'String'
        },
        {
          key: '_email',
          label: 'Email',
          groupable: false,
          type: 'String'
        },
        {
          key: '_phone',
          label: 'Telefone',
          groupable: false,
          type: 'String'
        }
      ],
      optionsDisplayTitle: false,
      message: null,
      imageUrl: '',
      uuid: '',
      charactersLimit: 300,
      myTemplate: null,
      places: {
        startMessage: { commit: 'survey/updateMessageProps' },
        endMessages: { commit: 'survey/updateEndMessageProps' },
        answeredMessage: { commit: 'survey/updateImagesLogo' },
        emailSettings: { commit: 'sendhub/updateImageProps' },
        wppImage: { commit: 'sendhub/updateImageWpp' },
        imageLogoUrl: { commit: 'account/updateImageLogoUrl' },
        imageMainLogo: { commit: 'account/updateImageMainLogo' },
        imageSmallLogo: { commit: 'account/updateImageSmallLogo' },
        surveySmallLogo: { commit: 'survey/updateImageSmallLogo' },
        userImage: { commit: 'account/updateUserImage' }
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('change-template')
    this.$root.$off('update-image-wpp')
    this.$root.$off('changed-name-wpp')
    this.$root.$off('current-length-wpp')
  },
  async mounted () {
    this.myTemplate = this.emailSettings.whatsapp
    this.lengthMessage = this.myTemplate?.params[0].value.length
    this.newText = this.myTemplate?.params[0].value || this.$i18n.t('whatsapp.hello') + ' *|_name|*'

    this.$root.$on('change-template', payload => {
      this.myTemplate = payload
    })

    this.$root.$on('current-length-wpp', payload => {
      this.lengthMessage = payload
    })

    if (this.user.account.fields.length > 0) {
      this.user.account.fields.forEach(e => this.metadataGroup.push(e))
    }
  },
  computed: {
    ...mapGetters({
      account: 'account/getAccount',
      user: 'account/getUser',
      survey: 'survey/getSurvey',
      emailSettings: 'sendhub/getEmailSettings'
    }),
    wppImage () {
      const result = {}
      result.value = this.emailSettings.images[1] && this.emailSettings.images[1].alias === 'whatsapp' ? this.emailSettings.images[1].value : this.emailSettings.images[0].value
      result.where = 'wppImage'
      result.success = (this.emailSettings.images[1] && this.emailSettings.images[1].value) || (this.emailSettings.images[0] && this.emailSettings.images[0].value)
      result.hideDelete = true
      result.crop = '400x400'
      result.imagesOnly = true
      return result
    },
    charactersLeft () {
      let char = this.lengthMessage
      const limit = this.charactersLimit
      if (char >= limit) { char = 0 }
      return { remaining: (limit - char), limit: limit }
    }
  },
  methods: {
    updateNameTitle (e) {
      if (!e.length) {
        return this.$store.commit('alerts/alert', {
          message: this.$t('whatsapp.alert_title_empty'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.newText = e
      this.$root.$emit('name-wpp-changed', e)
    },
    closeOptionsDisplay () {
      this.optionsDisplayTitle = false
    },
    returnUrl () {
      if (this.wppImage.value && typeof this.wppImage.value !== 'object') return this.wppImage.value
      if (this.wppImage.logo) return this.wppImage.logo
      if (this.wppImage.value) return this.wppImage.value.value
      return this.imageUrl
    },
    onSuccess (event) {
      if (this.wppImage) {
        this.imageUrl = event.cdnUrl
        this.$store.commit('sendhub/cropWidth', event.crop.width)
        if (this.wppImage.where === 'emailSettings' || this.wppImage.where === 'imageLogoUrl') {
          this.imageUrl = `${event.cdnUrl}-/setfill/ffffff/-/format/jpeg/-/resize/260x/`
        }
        this.wppImage.value = this.imageUrl
        this.wppImage.logo = this.imageUrl
        this.wppImage.imageUrl = this.imageUrl
        this.uuid = event.uuid
        this.wppImage.uuid = this.uuid
        this.wppImage.success = true
        const objectToCommit = this.formatCommit()
        this.$store.commit(objectToCommit.commit, objectToCommit.object)
        this.$root.$emit('update-image-wpp')
        this.$emit('toEmailImage', true)
        this.$emit('doneWithSuccess')
        this.$forceUpdate()
      }
    },
    onError (event) {
      if (event && this.wppImage) {
        this.wppImage.success = false
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('global.unknown_error'),
          showAlert: true
        })
      }
    },
    formatCommit () {
      let objectToCommit = {}
      Object
        .keys(this.places)
        .forEach(function (key, index) {
          if (key === this.wppImage.where) {
            objectToCommit = this.places[key]
            objectToCommit.object = {}
            objectToCommit.object.value = this.imageUrl
            objectToCommit.object.logo = this.imageUrl
            objectToCommit.object.alias = 'wppImage'
            objectToCommit.object.imageUrl = this.imageUrl
            objectToCommit.object.uuid = this.uuid
            objectToCommit.object.where = key
          }
        }, this)
      return objectToCommit
    }
  }
}
</script>

<style lang="scss">
.container-config-wpp {
  width: 100%;
  min-height: 150px;
  max-height: 500px;
  padding: 10px;
  .section-config-wpp {
    width: 100%;
    min-height: 150px;
    border: 1px solid #cacaca;
    border-radius: 10px;
    .header-config-wpp {
      width: 100%;
      height: 40px;
      background: var(--secondary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #cacaca;
      border-radius: 10px 10px 0 0;
      .title-config-wpp {
        color: var(--default-text-color);
        margin: 0;
      }
    }
    .content-config-wpp {
      width: 100%;
      min-height: 110px;
      padding: 10px;
      .name-config-wpp {
        position: relative;
        width: 100%;
        height: 210px;
        padding: 5px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #cacaca;
        .title-name-config-wpp {
          margin: 0;
          color: #454548;
          font-weight: 400;
        }
        .select-name-config-wpp {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .input-edit-title {
            display: flex;
            justify-content: center;
            width: 100%;
            height: fit-content;
            .div-auto-resize {
              width: 90%;
              height: fit-content;
              #whatsapp-name {
                width: 100% !important;
                border: 1px dashed gray !important;
                border-radius: 10px;
                font-size: 16px !important;
                font-weight: normal !important;
                padding: 5px !important;
              }
            }
          }
          .icon-help-name-wpp {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 18px;
            color: #454548;
            cursor: help;
          }
          .name-selector {
            width: 100%;
          }
        }
      }
      .select-image-config-wpp {
        position: relative;
        margin-top: 10px;
        width: 100%;
        height: 250px;
        border-radius: 10px;
        border: 1px solid #cacaca;
        padding: 5px;
        .title-img-config-wpp {
          height: 10%;
          margin: 0;
          color: #454548;
        }
        .icon-help-img-wpp {
          font-size: 18px;
          position: absolute;
          top: 5px;
          right: 5px;
          color: #454548;
          cursor: help;
        }
        .content-image-config-wpp {
          width: 100%;
          height: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          .content-upload-img-wpp {
            width: 90%;
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            .set-image {
              width: 70%;
              height: 95%;
              margin: 0;
              border: 1px dashed #838383 !important;
              display: flex;
              align-items: center;
              justify-content: center;
              div {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .img-container {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  margin: 0;
                }
                .btn-change-img-wpp {
                  position: absolute;
                  right: 5px;
                  bottom: 5px;
                  background: var(--secondary-color);
                  border-radius: 50%;
                  width: 35px;
                  height: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .add-image-wpp {
                    margin: 0;
                    font-size: 18px;
                    color: var(--default-text-color);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
