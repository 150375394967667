<template>
  <div class="container-qrcode">
    <app-button
      class="btn-qrcode"
      @click="newQrCode"
      variation="primary"
      >{{ $t(messageButton) }}
    </app-button>
    <div class="section-qrcode">
      <div class="data-loading" v-if="isLoading">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
      </div>
      <div class="content-qrcode" v-if="urlSave || showQrCode">
        <img class="img-qrcode" :src="urlSave" v-if="urlSave" @click="downloadImage(urlSave)">
        <qrcode-vue v-show="showQrCode && !urlSave" :level="'Q'" :size="200" class="my-qrcode" :value="value"></qrcode-vue>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { uploadcareService, surveyService } from '@/_services'
import { uploadDirect } from '@uploadcare/upload-client'

export default {
  name: 'QrCodeGenerator',
  props: ['link'],
  components: {
    QrcodeVue,
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      isLoading: false,
      fileName: '',
      urlSave: '',
      value: '',
      urlImage: '',
      showQrCode: false,
      messageButton: 'sendhub.generate_qrcode'
    }
  },
  mounted () {
    if (this.survey.qrCodeLink) {
      this.isLoading = true
      this.messageButton = 'sendhub.new_qrcode'
      const containsBarInLink = this.survey.qrCodeLink.charAt(this.survey.qrCodeLink.length - 1)
      this.urlSave = this.survey.qrCodeLink
      if (containsBarInLink !== '/') {
        this.urlSave = this.survey.qrCodeLink + '/'
      }
      this.isLoading = false
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  },
  methods: {
    newQrCode () {
      this.fileName = `${this.survey.title}_qr_code`
      this.isLoading = true
      this.urlSave = ''
      this.value = this.link
      this.showQrCode = true
      setTimeout(() => {
        const canvas = document.querySelector('.my-qrcode').childNodes[0]
        canvas.toBlob((blob) => {
          const fileBlob = new File([blob], `${this.fileName}.jpg`, { type: 'image/jpeg' })
          this.setQrCode(fileBlob, canvas)
        }, 'image/jpeg')
      }, 10)
    },
    async setQrCode (file, canvas) {
      const { publicKey, signature: secureSignature, expire: secureExpire } = await uploadcareService.generateHash()
      const result = await uploadDirect(file, {
        publicKey,
        secureSignature,
        secureExpire,
        store: 'auto'
      })
      if (!result) {
        this.isLoading = false
        return
      }
      this.urlImage = `https://img.binds.co/${result.uuid}/`
      this.$store.commit('survey/survey', { qrCodeLink: this.urlImage })
      const updateLinkQrCode = await surveyService.updateSurvey({ qrCodeLink: this.urlImage, _id: this.survey._id }, true)
      if (updateLinkQrCode) {
        this.messageButton = 'sendhub.new_qrcode'
        this.showQrCode = false
        this.urlSave = this.urlImage
        this.downloadBase64(canvas)
      }
    },
    downloadBase64 (canvas) {
      let titleDownload = this.fileName
      if (titleDownload.length > 50) {
        titleDownload = titleDownload.slice(0, 50)
      }
      const link = document.createElement('a')
      link.download = titleDownload.replaceAll(' ', '_')
      canvas.toBlob(blob => {
        link.href = URL.createObjectURL(blob)
        link.click()
      })
      this.isLoading = false
    },
    async downloadImage (url) {
      let titleDownload = this.fileName
      if (titleDownload.length > 50) {
        titleDownload = titleDownload.slice(0, 50)
      }
      const image = await fetch(url)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement('a')
      link.href = imageURL
      link.download = titleDownload.replaceAll(' ', '_')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style lang="scss">
 .container-qrcode {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .btn-qrcode {
    width: 215px;
    margin: 0;
    background: var(--primary-color) !important;
    box-shadow: none !important;
  }
  .btn-qrcode:hover {
    background: var(--accent-color) !important;
  }
  .section-qrcode {
    position: relative;
    margin: 15px 0;
    .content-qrcode {
      width: 200px;
      height: 200px;
      .img-qrcode {
        width: 100%;
        height: 100%;
      }
    }
    .data-loading {
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner {
        margin: 0 !important;
      }
    }
  }
 }
</style>
